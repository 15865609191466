import { API_ENDPOINTS, PAGE_SIZE, PATIENT_API_ENDPOINTS } from "../config";
import { http } from "../utils/http";
import { store } from "../State/store/configureStore";
import { ROLE_PATIENT } from "../security/roles";
import moment from "moment";
import fileService from "./fileService";
import { showProgress, closeProgress } from "../State/actions/uploadProgress";
class SurveysService {
  add(survey) {
    return http.request(API_ENDPOINTS.addSurvey(), "post", survey);
  }

  edit(surveyId, survey) {
    return http.request(API_ENDPOINTS.loadSurvey(surveyId), "patch", survey);
  }
  importSurveyResponse(
    survey,
    csvFile,
    multiGroupColumn,
    multiGroupValueColumn
  ) {
    let formData = new FormData();
    formData.append("files", csvFile);
    if (multiGroupColumn && multiGroupValueColumn) {
      formData.append("multiGroupColumn", multiGroupColumn);
      formData.append("multiGroupValueColumn", multiGroupValueColumn);
    }
    let api = API_ENDPOINTS.patientSurveyImportFromCSV(survey);
    return http
      .request(
        API_ENDPOINTS.patientSurveyImportFromCSV(survey),
        "post",
        formData,
        null,
        (progressEvent) => {
          const uploadProgress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          store.dispatch(showProgress(uploadProgress));
        },
        120000
      )
      .then((response) => {
        store.dispatch(closeProgress());
        let strings = [];
        if (response._embedded && response._embedded.strings) {
          strings = response._embedded.strings || [];
        }
        return strings;
      })
      .catch((e) => {
        store.dispatch(closeProgress());
      });
  }
  load(page, all, active, patientTypeId) {
    let pageSize = PAGE_SIZE;
    if (all) {
      pageSize = 1000;
    }

    let endpoint =
      API_ENDPOINTS.loadSurveys() + `?page=${page}&size=${pageSize}`;
    if (!active) {
      endpoint += `&allStatus=True`;
    }

    if (patientTypeId) {
      endpoint += `&pTypeId=${patientTypeId}`;
    }

    return http.request(endpoint).then((data) => {
      if (data && data._embedded) {
        return {
          data: page <= data.page.totalPages ? data._embedded.surveys : [],
          loadMore: data.page.totalPages > page + 1,
        };
      } else {
        return {
          data: [],
          loadMore: false,
        };
      }
    });
  }

  loadSingle(surveyId, patientId) {
    let api = API_ENDPOINTS.loadSurvey(surveyId);
    if (patientId) {
      api += `?patientId=${patientId}`;
    }
    return http.request(api);
  }

  loadPatientSurveys(patientId) {
    return http
      .request(API_ENDPOINTS.loadPatientSurveys(patientId))
      .then((data) => {
        return data && data._embedded ? data._embedded.surveyResponses : [];
      });
  }

  deleteSurvey(surveyId) {
    return http.request(API_ENDPOINTS.loadSurvey(surveyId), "delete");
  }

  submitAnswers(messageId, responseData, patientId) {
    let role = store.getState().user.role;
    let api = messageId
      ? API_ENDPOINTS.surveyResponse(patientId, messageId)
      : API_ENDPOINTS.directSurveyResponse(patientId);
    if (role === ROLE_PATIENT) {
      if (messageId) {
        api = PATIENT_API_ENDPOINTS.surveyResponse(messageId);
      } else {
        api = PATIENT_API_ENDPOINTS.surveyResponsesAdHoc;
      }
    }
    return http.request(api, "post", responseData);
  }

  fetchDetailedReportUrl(patientId, startDate, endDate) {
    let api = API_ENDPOINTS.detailedAssessmentReport(patientId) + "?";
    if (startDate) {
      api += `&startDate=${startDate}`;
    }
    if (endDate) {
      api += `&endDate=${endDate}`;
    }
    return api;
  }

  fetchTherapyComplianceReportUrl(patientId, endDate) {
    let api = API_ENDPOINTS.therapyComplianceReport(patientId);
    if (endDate) {
      api += "?endDate=" + endDate;
    }
    return api;
    // return http.request(api);
  }

  fetchPatientReportData(patientId, filter = {}) {
    let api = API_ENDPOINTS.patientCustomReport(patientId) + "?";

    if (filter && filter.duration) {
      let utcMoment = moment.utc;
      let duration = filter.duration;
      let fromDate = null,
        toDate = null;
      switch (duration) {
        case "today":
          fromDate = utcMoment().format("DDMMYYYY");
          toDate = utcMoment().format("DDMMYYYY");
          break;
        case "7days":
          fromDate = utcMoment().subtract(7, "days").format("DDMMYYYY");
          toDate = utcMoment().format("DDMMYYYY");
          break;
        case "10days":
          fromDate = utcMoment().subtract(10, "days").format("DDMMYYYY");
          toDate = utcMoment().format("DDMMYYYY");
          break;
        case "1month":
          fromDate = utcMoment().subtract(1, "months").format("DDMMYYYY");
          toDate = utcMoment().format("DDMMYYYY");
          break;
        case "2month":
          fromDate = utcMoment().subtract(2, "months").format("DDMMYYYY");
          toDate = utcMoment().format("DDMMYYYY");
          break;
        case "3month":
          fromDate = utcMoment().subtract(3, "months").format("DDMMYYYY");
          toDate = utcMoment().format("DDMMYYYY");
          break;
        case "6month":
          fromDate = utcMoment().subtract(6, "months").format("DDMMYYYY");
          toDate = utcMoment().format("DDMMYYYY");
          break;
        case "12month":
          fromDate = utcMoment().subtract(1, "years").format("DDMMYYYY");
          toDate = utcMoment().format("DDMMYYYY");
          break;
        default:
          fromDate = utcMoment().subtract(7, "days").format("DDMMYYYY");
          toDate = utcMoment().format("DDMMYYYY");
          break;
      }
      if (fromDate) {
        api += `&fromDate=${fromDate}`;
      }
      if (toDate) {
        api += `&toDate=${toDate}`;
      }
    }

    return http.request(api);
  }

  fetchAnonymousPatientReportData = (filter = {}) => {
    let { patientTypeId, fromDate, toDate } = filter;
    let api = API_ENDPOINTS.patientCustomReportAnonymous(patientTypeId);
    if (fromDate) {
      api += `&fromDate=${new Date(fromDate).getTime()}`;
    }
    if (toDate) {
      api += `&toDate=${new Date(toDate).getTime()}`;
    }

    return http.request(api);
  };

  loadPendingSurveysForPatient(patientId) {
    return http
      .request(API_ENDPOINTS.provisionedSurveys(patientId))
      .then((data) => {
        return data && data._embedded ? data._embedded.provisionedSurveys : [];
      });
  }

  loadPatientSubmittedSurveys(patientId, flag) {
    return http
      .request(API_ENDPOINTS.patientSubmittedSurveys(patientId, flag))
      .then((data) => {
        return data
          ? {
              availableSurveys: data.availableSurveys,
              latestSurveyId: data.latestSurveyId,
            } || {}
          : {};
      });
  }

  loadSubmittedSurveyResponsesBySurveyId(
    patientId,
    surveyId,
    page = 0,
    size = 30
  ) {
    return http
      .request(
        API_ENDPOINTS.patientSurveysResponsesById(
          patientId,
          surveyId,
          page,
          size
        )
      )
      .then((data) => {
        return {
          responses:
            data && data._embedded ? data._embedded.surveyResponses : [],
          totalPages: data.page.totalPages,
        };
      });
  }

  deleteSurveyResponse(patientId, responseId) {
    return http.request(
      API_ENDPOINTS.surveyResponses(patientId, responseId),
      "delete"
    );
  }

  exportSurvey(surveyId) {
    return http.request(API_ENDPOINTS.surveyExport(surveyId));
  }

  downloadSurveyResponsesAsCSV(patientId, surveyId) {
    return fileService.getFile(
      API_ENDPOINTS.surveyResponsesCSV(patientId, surveyId)
    );
  }

  fetchAGP(patientId, start, end) {
    return http
      .request(API_ENDPOINTS.fetchAGPData(patientId, start, end))
  }
}

const surveysService = new SurveysService();

export default surveysService;
