export const REACT_APP_HOSTING_ENV =
  process.env.REACT_APP_HOSTING_ENV || "main-dev";

const Config = (env) => {
  switch (env) {
    case "main-prod": {
      return [
        {
          env: "production",
          region: "PREPROD",
          url: "https://preprodapi.mypatientspace.com",
          regionCode: "preprod",
          appURL: "https://preprod.mypatientspace.com",
        }
      ];
    }
    default: {
      return [
        {
          env: "development",
          region: "PROD",
          url: "https://api.mypatientspace.com",
          regionCode: "prod",
          appURL: "https://web.mypatientspace.com",
        },
        {
          env: "development",
          region: "PREPROD",
          url: "https://preprodapi.mypatientspace.com",
          regionCode: "preprod",
          appURL: "https://preprod.mypatientspace.com",
        },
        {
          env: "development",
          region: "TEST",
          url: "https://testapi.mypatientspace.com",
          regionCode: "test",
          appURL: "https://test.mypatientspace.com",
        },
        {
          env: "development",
          region: "UAT",
          url: "https://uatapi.mypatientspace.com",
          regionCode: "uat",
          appURL: "https://uat.mypatientspace.com",
        },
        {
          env: "development",
          region: "UAE",
          url: "https://uaeapi.mypatientspace.com",
          regionCode: "uae",
          appURL: "https://uae.mypatientspace.com",
        },
        {
          env: "development",
          region: "FR",
          url: "https://franceapi.mypatientspace.com",
          regionCode: "fr",
          appURL: "https://france.mypatientspace.com",
        },
      ];
    }
  }

};

export default Config(REACT_APP_HOSTING_ENV);
