import React, { PureComponent } from "react";
import Select from "react-select";
import classnames from "classnames";
import userService from "../services/userService";
import { getAPIURL, getServerRegions } from "../config";
import I18nProvider from "../services/I18nProvider";

class RegionSelector extends PureComponent {
  constructor(props) {
    super(props);
    // let regionUrl = StorageService.getFromLocalStorage(API_URL_KEY);
    let regionUrl = getAPIURL();
    this.state = {
      region: regionUrl
    };
  }

  onUpdateRegion = option => {
    this.setState({ region: option.url });
    userService.setServerURL(option.url);
  };

  render() {
    let { loading, error, style } = this.props;
    return getServerRegions().length > 1 ? (
      <Select
        rtl={I18nProvider.isRTLLocale()}
        value={this.state.region}
        onChange={this.onUpdateRegion}
        options={getServerRegions()}
        valueKey="url"
        labelKey="region"
        placeholder="Region"
        disabled={loading}
        className={classnames(
          error ? "is-invalid" : this.props.className || ""
        )}
        id="login-select-region"
        clearable={false}
        style={style || {}}
      />
    ) : null;
  }
}

export default RegionSelector;
