import { take, put, select } from "redux-saga/effects";

import history from "../../history";
import {
  LOAD_PATIENTS,
  PATIENTS_LOADED,
  PATIENT_UPDATE,
  PATIENT_UPDATE_DONE,
  PATIENT_ADD_DONE,
  PATIENT_ADD,
  LOAD_PATIENT,
  LOAD_PATIENT_DONE,
  PATIENT_ADD_ERROR,
  LOAD_SAVED_SEARCHES,
  SAVED_SEARCHES_LOADED
} from "../actions/actionTypes";

import PatientsService from "../../services/patientsService";

export function* addPatient() {
  while (true) {
    const action = yield take(PATIENT_ADD);
    yield put({ type: PATIENT_ADD });
    try {
      const result = yield PatientsService.add(action.data);
      console.log("ADD PATIENT ", result);
      yield put({ type: PATIENT_ADD_DONE, data: result });
      //history.push('/organizations/' + result.facilityId + '/patients');
    } catch (error) {
      console.log("ERROR", error);
      yield put({ type: PATIENT_ADD_ERROR, error });
    }
  }
}

export function* updatePatient() {
  while (true) {
    const action = yield take(PATIENT_UPDATE);
    try {
      const result = PatientsService.update(action.data);
      yield put({
        type: PATIENT_UPDATE_DONE,
        data: result
      });
      history.push(`/facilities/${action.data.facilityId}/patients`);
    } catch (error) {
      console.log("ERROR", error);
    }
  }
}

export function* loadPatients() {
  while (true) {
    const action = yield take(LOAD_PATIENTS);

    // get current page and load more
    const patients = yield select((state) => state.patients);
    // check if loadMore is true or already loading
    if (!patients.loading && "loadMore" in patients && !patients.loadMore) {
      // load more should not fire as loadMore is false
      return false;
    }
    // get page
    const page = patients.page || 0;

    // start loading more

    // put will set loading flag
    yield put({ type: LOAD_PATIENTS });
    // load patients

    try {
      const count = yield PatientsService.loadCount(action.searchCriteria);
      const result = yield PatientsService.load(
        page,
        action.searchCriteria,
        count
      );
      // console.log('SAGE RESULT RETURNED ', result);
      yield put({
        type: PATIENTS_LOADED,
        list: result.data,
        page: page + 1,
        loadMore: result.loadMore,
        total: count,
      });
    } catch (error) {
      // console.log('SAGE RESULT ERROR ');
      yield put({
        type: PATIENTS_LOADED,
        list: [],
        page: page + 1,
        loadMore: false
      });
      console.log("ERROR", error);
    }
  }
}

export function* loadPatient() {
  while (true) {
    const action = yield take(LOAD_PATIENT);
    // put will set loading flag
    yield put({ type: LOAD_PATIENT });
    // load patients
    try {
      const id = action.id;
      // const loadSpaceDetails = action.loadSpaceDetails;
      const result = yield PatientsService.loadSingle(id);
      // if (loadSpaceDetails) {
      //   const spaceDetails = yield PatientsService.loadSpaceDetails(id);
      // }
      yield put({
        type: LOAD_PATIENT_DONE,
        data: result
      });
    } catch (error) {
      console.log("ERROR", error);
    }
  }
}

export function* loadSavedSearches() {
  while (true) {
    const action = yield take(LOAD_SAVED_SEARCHES);

    try {
      const result = yield PatientsService.loadAllSavedSearches();
      // console.log('SAGE RESULT RETURNED ', result);
      yield put({
        type: SAVED_SEARCHES_LOADED,
        savedSearches: result
      });
    } catch (error) {
      // console.log('SAGE RESULT ERROR ');
      yield put({
        type: SAVED_SEARCHES_LOADED,
        savedSearches: []
      });
      console.log("ERROR", error);
    }
  }
}
