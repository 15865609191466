import { http } from "../utils/http";
import { API_ENDPOINTS, PAGE_SIZE } from "../config";
import { store } from "../State/store/configureStore";
import { isStaff, ROLE_FACILITY_ADMIN } from "../security/roles";
import { closeProgress, showProgress } from "../State/actions/uploadProgress";

class StaffService {
  static delete(staffId) {
    return http.request(API_ENDPOINTS.deleteStaff(staffId), "delete");
  }

  static invite(data, activeInternal) {
    let url = API_ENDPOINTS.inviteStaff(
      data.hasOwnProperty("loginUserId") || activeInternal
    );
    if (activeInternal) {
      url += "?makeActiveInternal=true";
    }
    return http.request(url, "post", data);
  }

  static load(page, all, showInactive, forOrg, searchTerm) {
    let api = null;
    let role = store.getState().user.role;
    if (isStaff(role)) {
      if (forOrg) {
        api = API_ENDPOINTS.loadStaff();
      } else {
        api = API_ENDPOINTS.loadAllStaffForStaff();
      }
    } else {
      api = API_ENDPOINTS.loadStaff();
    }

    let pageSize = 10;
    if (all) {
      pageSize = 1000;
    }

    api = api + `?page=${page}&size=${pageSize}`;
    if (showInactive) {
      api += "&allStatus=true";
    }

    if (forOrg) {
      api += "&forOrg=true";
    }

    if (searchTerm) {
      api += "&searchTerm=" + searchTerm;
    }

    return http.request(api).then((data) => {
      if (data && data._embedded) {
        return {
          data: data._embedded.staffs || [],
          loadMore: data.page.totalPages > page + 1,
          totalStaff: data.page.totalElements,
        };
      } else {
        return {
          data: [],
          loadMore: false,
          totalStaff: 0,
        };
      }
    });
  }

  static loadConsultants() {
    return http.request(API_ENDPOINTS.consultants).then((data) => {
      return data && data._embedded ? data._embedded.staffWithRoles : [];
    });
  }

  static loadOneStaff(staffId) {
    let api = API_ENDPOINTS.loadOneStaff(staffId);
    return http.request(api);
  }

  static loadProfile() {
    return http.request(API_ENDPOINTS.loadStaffProfile);
  }

  static editProfile(data) {
    return http.request(API_ENDPOINTS.loadStaffProfile, "patch", data);
  }

  static createCareTeam(data) {
    return http.request(API_ENDPOINTS.careTeams, "post", data);
  }

  static editCareTeam(careTeamId, data) {
    return http.request(
      API_ENDPOINTS.careTeams + "/" + careTeamId,
      "patch",
      data
    );
  }

  static deleteCareTeam(careTeamId) {
    return http.request(API_ENDPOINTS.careTeams + "/" + careTeamId, "delete");
  }

  static getCareTeams() {
    return http
      .request(API_ENDPOINTS.careTeams + "?page=0&size=500")
      .then(data => {
        if (data && data._embedded) {
          return data._embedded.careTeams;
        } else {
          return [];
        }
      });
  }

  static editStaff(staffId, data) {
    return http.request(API_ENDPOINTS.editStaff(staffId), "patch", data);
  }

  static reinviteStaff(staffId, data) {
    return http.request(API_ENDPOINTS.reInviteStaff(staffId), "post", data);
  }

  static getStaffLocations() {
    return http.request(API_ENDPOINTS.allResmedLocations).then(data => {
      // {"Response":{"totalCount":"1","locations":[{"name":"PEI - MPS","id":"4783"}]}}
      return data && data.Response ? data.Response.locations || [] : [];
    });
  }

  static getClinicalUSerNames(locId) {
    return http.request(API_ENDPOINTS.resmedUsers(locId)).then(data => {
      return data && data.Response ? data.Response.clinicalUsers || [] : [];
    });
  }

  static restoreStaff(staffId) {
    return http.request(API_ENDPOINTS.restoreStaff(staffId), "post");
  }

  static resetPassword(staffId, loginUserId) {
    return http.request(API_ENDPOINTS.resetStaffPassword(staffId), "post", {
      loginUserId,
    });
  }

  static async importUsingCSVFile(csvFile) {
    const formData = new FormData();
    formData.append("files", csvFile);
    const response = await http.request(
      API_ENDPOINTS.importStaff(),
      "post",
      formData,
      null,
      (progressEvent) => {
        const uploadProgress = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        store.dispatch(showProgress(uploadProgress));
      },
      120000
    );
    store.dispatch(closeProgress());
    let strings = [];
    if (response._embedded && response._embedded.strings) {
      strings = response._embedded.strings || [];
    }
    return strings;
  }

  static async loadOrganizations() {
    const data = await http.request(API_ENDPOINTS.organizations());
    return data;
  }

  static async loadSubOrganizations(orgId) {
    const data = await http.request(API_ENDPOINTS.subOrganizations(orgId));
    return data;
  }

  static loadOrganization(organizationId) {
    let api = API_ENDPOINTS.organizations(organizationId);
    return http.request(api);
  }

  static createOrganization(data) {
    return http.request(API_ENDPOINTS.organizations(), "post", data);
  }

  static editOrganization(organizationId, data) {
    return http.request(
      API_ENDPOINTS.organizations(organizationId),
      "patch",
      data
    );
  }

  static addOrganizationStaff(
    organizationId,
    staffId,
    staffData = {},
    autoCreateDoctorTeamForStaff
  ) {
    let api = API_ENDPOINTS.organizationStaff(organizationId) + "?";
    if (staffId) {
      api += "&staffId=" + staffId;
    }
    if (staffData && staffData.dontSendInvite) {
      api += "&makeActiveInternal=true";
    }
    if (autoCreateDoctorTeamForStaff) {
      api += "&autoCreateDoctorTeamForStaff=true";
    }
    return http.request(api, "post", staffData);
  }

  static loadRoleTemplates() {
    return http.request(API_ENDPOINTS.roleTemplates()).then((data) => {
      if (data && data._embedded) {
        return {
          roleTemplates: data._embedded.roleTemplates || [],
        };
      } else {
        return {
          roleTemplates: [],
          loadMore: false,
        };
      }
    });
  }

  static createRoleTemplate(data) {
    return http.request(API_ENDPOINTS.roleTemplates(), "post", data);
  }

  static editRoleTemplate(roleId, data) {
    return http.request(API_ENDPOINTS.roleTemplates(roleId), "patch", data);
  }

  static removeStaffFromOrganization(orgId, staffId) {
    return http.request(
      API_ENDPOINTS.organizationStaff(orgId, staffId),
      "delete"
    );
  }

  static async loadOrganizationStaff(organizationId, page) {
    let api =
      API_ENDPOINTS.organizationStaff(organizationId) +
      "?page=" +
      page +
      "&size=10";
    const data = await http.request(api);
    if (data && data._embedded) {
      return {
        staff: data._embedded.staffs || [],
        pageInfo: data.page,
      };
    }
    return {
      staff: [],
      pageInfo: { number: -1 },
    };
  }

  static sendConversationReadStaff(patientId, messageId, textIdArray) {
    return http.request(
      API_ENDPOINTS.sendConversationReadStaff(patientId, messageId),
      "post",
      textIdArray
    );
  }
}

const staffService = StaffService;

export default staffService;
